
import React, { useEffect, useState } from 'react';

import "../styles/globals.scss";
import { useRouter } from 'next/router'
import { SSRProvider } from '@react-aria/ssr';
import dynamic from 'next/dynamic';
import AOS from "aos";
import "aos/dist/aos.css";
import { appWithTranslation } from 'next-i18next';

import { ToastContainer } from 'react-toastify';
// import { IntercomProvider } from 'react-use-intercom';

// Moralis
import { MoralisProvider } from "react-moralis";
import { MORALIS_SERVER_URL } from "../config/ethereum-config";

// Page Plugins
const Layout = dynamic(() => import('../components/layout'));

// Wallet
import { createClient, configureChains, WagmiConfig } from 'wagmi';
import { SessionProvider } from 'next-auth/react';
// import { mainnet, goerli, polygon, polygonMumbai } from '@wagmi/core';
// import { configureChains, createClient } from 'wagmi'
import { mainnet, goerli, polygon, polygonMumbai } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { Analytics } from '@vercel/analytics/react';
import Context from "../context/Context";
import Script from "next/script";
import * as gtag from "../helper/gtag";

import constHelper from "../helper/constHelper"

const INTERCOM_APP_ID = 'l9jx7gys';

// const { provider, webSocketProvider } = configureChains(defaultChains, [publicProvider()]);

// const wagmiClient = createClient({
//   provider,
//   webSocketProvider,
//   autoConnect: true,
// });

const { chains, provider } = configureChains(
  [mainnet, goerli, polygon, polygonMumbai],
  [alchemyProvider({ apiKey: '3-4sakWCe4_IgK3tZ8g33VwRp6rjyeda' }), publicProvider()],
)
 
const wagmiClient = createClient({
  autoConnect: true,
  connectors: [new InjectedConnector({ chains,
  //   options: {
  //   shimChainChangedDisconnect: false,
  // } 
})],
  provider,
})


export function reportWebVitals(metric) {
  try {
    const { id, label, name, value } = metric;
    if (window.gtag) {
      window.gtag('event', name, {
        event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        event_label: id, // id unique to current page load
        non_interaction: true, // avoids affecting bounce rate.
      });
    }
  } catch (error) {
    console.log("error " + error);
  }
}

function MyApp({ Component, pageProps }) {

  const router = useRouter();
  const { locale } = router;
  const [networkChangeModal, setNetworkChangeModal] = useState(false);  
  const [localChainIndex,setLocalChainIndex] = useState(1);
  const serverUrl = MORALIS_SERVER_URL;
  const appId = process.env.NEXT_PUBLIC_MORALIS_APP_ID;
  useEffect(() => {
    document.documentElement.lang = locale;
    document.documentElement.dir = (locale === 'ar' ? 'rtl' : 'ltr');
  }, [locale]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };

    // Subscribe to the change event
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {

    let chLogin = checkLoginOrNot();

    if (router.pathname === "nft/upload-variants") {
      if (!chLogin) { router.push('/nft/login'); }
    }
    else if (router.pathname === "/nft/upload-details/[nftType]") {
      if (!chLogin) { router.push('/nft/login'); }
    }
    else if (router.pathname === "/nft/profile-edit/[profileId]") {
      if (!chLogin) { router.push('/nft/login'); }
    }

  }, [router])


  useEffect(() => {
    if (localStorage.getItem('theme') && localStorage.getItem('theme') !== '') {
      if(router.pathname.includes("/[did]")){
        document.body.setAttribute("data-theme", constHelper.LIGHT_THEME);
      }
      else{
        document.body.setAttribute("data-theme", localStorage.getItem('theme'));
      }
    }
  },[]);

  const checkLoginOrNot = () => {
    return localStorage.getItem('walletAddress') && localStorage.getItem('walletAddress') !== ""
  }

  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 50,
      disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Script
          id="gtag"
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG}`}
      />
      <Script
          id={"gtag1"}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
      />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
                `}
      </Script>
     {/*<IntercomProvider appId={INTERCOM_APP_ID} initializeDelay={5000} autoBoot={true}>*/}
        <SSRProvider>
        <WagmiConfig client={wagmiClient}>
      <SessionProvider session={pageProps.session} refetchInterval={0}>
          <MoralisProvider serverUrl={serverUrl} appId={appId} initializeOnMount>
            <Context>
              <Layout {...pageProps} pathname={router?.pathname} changeNetworkModal={(res) => {
                if(res){
                  const chainIndexStorage = localStorage.getItem("chainIndex");
                  if(chainIndexStorage){
                    setLocalChainIndex(chainIndexStorage);
                  }
                };
                setNetworkChangeModal(res)}
                }>
                <Component {...pageProps} />
              </Layout>

               {/* NetworkModal */}
               {/* <NetworkModal networkChangeModal={networkChangeModal} chainIndex={localChainIndex}/> */}

              <ToastContainer />
            </Context>
          </MoralisProvider>
          </SessionProvider>
          </WagmiConfig>
        </SSRProvider>
      {/*</IntercomProvider>*/}
      <Analytics />
    </>
  )
}

export default appWithTranslation(MyApp);